/* General Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  color: #fff;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('https://upload-os-bbs.hoyolab.com/upload/2024/03/26/8009863/da9c9c5585c380cd3496460b59ab8eb8_9056287089500519195.gif');
  background-size: cover;
  background-position: center;
  position: relative;
}

/* Dark overlay */
.home-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.card {
  background: rgba(31, 29, 29, 0.85);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  position: relative;
  z-index: 2;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.title {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
}

.gradient-text {
  background: linear-gradient(to right, rgb(244, 170, 255), #72c7ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo {
  height: 60px;
  width: auto;
  margin-left: 1rem;
}

.subtitle {
  font-size: 1.2rem;
  color: #ccc;
  margin-bottom: 2rem;
}

.login-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, rgb(244, 170, 255), #72c7ff);
  color: #000;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(244, 170, 255, 0.4);
}

.icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
  color: #fff;
  text-align: center;
}

.dashboard-container h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.dashboard-container p {
  font-size: 1.2rem;
  color: #ccc;
}